import { RichText } from "prismic-dom";
import { ImageCredit } from "./types";
import React from "react";

const OPENING_TAG_PATTERN = /^<[a-zA-Z0-9]+>/;
const CLOSING_TAG_PATTERN = /<\/[a-zA-Z0-9]+>$/;

/**
 * Retrieve the "innerHTML" from a Prismic-rendered string of html,
 * by stripping the overall opening and closing tag.
 * @param raw {any} - The object containing prismic text to render
 */
export const innerHtmlFromPrismicRaw = (raw: any): string => {
  let toRender = raw;
  if (!Array.isArray(toRender)) {
    toRender = [toRender];
  }
  let rendered = RichText.asHtml(toRender);
  rendered = rendered.replace(OPENING_TAG_PATTERN, "");
  rendered = rendered.replace(CLOSING_TAG_PATTERN, "");
  return rendered;
};

/**
 * Generate JSX for an image's credit.
 * @param title
 * @param credit
 */
export const generateCaption = (
  title: string,
  credit?: ImageCredit
): React.ReactNode => {
  if (credit) {
    return (
      <>
        {title} (Credit{" "}
        <a href={credit.link} target="_blank" rel="noreferrer">
          {credit.title}
        </a>
        )
      </>
    );
  } else {
    return <>title</>;
  }
};

/**
 * Generate a string of HTML for an Image's caption
 * @param title
 * @param credit
 */
export const generateCaptionInnerHtmlString = (
  title: string,
  credit?: ImageCredit
): string => {
  if (credit) {
    return `${title} (Credit <a href="${credit.link}" target="_blank" rel="noreferrer">${credit.title}</a>)`;
  } else {
    return title;
  }
};
